<template>
    <div class="page overflow-hidden">
        <div class="flex items-center justify-center h-screen overflow-hidden">
            <slot />
        </div>
    </div>
</template>
<script setup>
const userStore = useUserStore();

const uiStore = useUiStore();

const { setTeams, setPlan, setUser } = userStore;

const { menu } = storeToRefs(uiStore);
const { setPlans } = uiStore;

const client = useSupabaseClient();


const headers = useRequestHeaders(["cookie"]);


const { pending, data: user } = await useLazyFetch("/api/users/user", {
    key: "user",
    default: () => ({
        user: userStore.user
    })
});


const fetchUserInfo = async () => {

    const { data: userPlan } = await useFetch("/api/users/plan", {
        method: "POST",
        headers,
    });
    // if (userPlanError) console.error(userPlanError)
    setPlan(userPlan.value)

    const { data: teams, error: teamsError } = await client
        .from("groups")
        .select("*")
        .in("id", Object.keys(user?.value?.app_metadata?.groups ?? {}));

    if (teamsError) {
        console.error(teamsError)
    }
    // setTeams({ teams: teams?.filter((group: any) => group.metadata.provisioned) })

    const { data: fetchedPlans } = await useFetch("/api/plans/list", {
        method: "GET",
        headers,
    });

    // if (fetchedPlansError) {
    //     console.error(fetchedPlansError)
    // }
    setPlans(fetchedPlans.value.products)

};

watch(
    () => user.value,
    async (newUser) => {
        if (!newUser) {
            await navigateTo('/login');
        } else {
            setUser(newUser);
            await fetchUserInfo();
        }
    },
    { immediate: true }
);


</script>